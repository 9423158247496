import React, { useEffect, useState } from 'react';
import styles from './User.module.css';
import ApiService from '../../services/apiService/ApiService';
import SignUp from '../signup/SignUp';
import decodeToken from '../../utils/decodeToken';
import enedpoints from '../../common/endpoints';
import ConfirmationAlert from '../confirmation/ConfirmationAlert';
import Alert from '../../utils/Alert';
import Loading from '../loading/Loading';

const User = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [uid, setUid] = useState(null);
    const [uName, setUName] = useState('');

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const handleShowConfirmationModal = (id, name) => {
        setUid(id);
        setUName(name);
        setShowConfirmationModal(true);
    }
    const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const handleShowUserModal = (id) => {
        setUid(id);
        setShowUserModal(true);
    };
    const handleCloseUserModal = () => setShowUserModal(false);
 

    useEffect(() => {
        getAllUsers();
    }, [showUserModal]);

    const getAllUsers = async() => {
        try {
            const response = await ApiService.getData(enedpoints.users);
            setUsers(response);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const handleDeleteUser = async(id) => {
        try {
            const response = await ApiService.deleteData(enedpoints.users, id);
            Alert('Warning',response.message, 'warning');
            getAllUsers();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className='container'>
            <div className='content'>
                {isLoading && <Loading />}
                <div className='header'>
                    <h1>User List</h1>
                    <span className={styles.error}></span>
                    { (decodeToken() && decodeToken().role) && (<button className='addBtn' onClick={() => handleShowUserModal(null)}>
                    New User
                    </button>)}
                </div>

                <div className='tableContainer'>
                    <table className='table'>
                    <thead>
                        <tr>
                        <th width="40%">Job Name</th>
                        <th width="25%">Product</th>
                        <th width="20%">Max Size</th>
                        <th width="15%">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users && users.map((user, index) => (
                        <tr key={index} className='row'>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            <td>
                            <span className='actions'>
                                {/* <span className='icon view'>
                                    <i className="fa fa-eye"></i>
                                </span> */}
                                {
                                    (decodeToken() && decodeToken().role) === 'superAdmin' && (
                                        <>
                                            <span className='icon edit' title='Edit' onClick={() => handleShowUserModal(user._id)}>
                                                <i className="fa fa-edit"></i>
                                            </span>
                                            {
                                                decodeToken().userId !== user._id && (
                                                    <span className='icon delete' title='Delete' onClick={() => handleShowConfirmationModal(user._id, user.username)}>
                                                        <i className="fa fa-trash"></i>
                                                    </span>
                                                )
                                            }
                                        </>
                                    )
                                }
                               
                            </span>

                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
            </div>
            
            {showUserModal && (
                <div className='modalOverlay'>
                    <SignUp onClose={handleCloseUserModal}  uid={uid}/>
                </div>
            )}
            {showConfirmationModal && (
                <div className='modalOverlay'>
                    <ConfirmationAlert message={`Are you sure to delete "${uName}"?`} closeModel={handleCloseConfirmationModal} onConfirm={() => handleDeleteUser(uid)} />
                </div>
            )}
        </div>
    )
};

export default User;