// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePage_app__iot\\+3 {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.HomePage_container__p6dYm {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/HomePage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC","sourcesContent":[".app {\n    display: flex;\n    width: 100vw;\n    height: 100vh;\n}\n\n.container {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `HomePage_app__iot+3`,
	"container": `HomePage_container__p6dYm`
};
export default ___CSS_LOADER_EXPORT___;
