const decodeToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const decodedToken = JSON.parse(window.atob(base64));
        return decodedToken;
    } else {
        return null;
    }
};

export default decodeToken;