// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPage_app__HZso9 {
    padding: 20px;
  }
  
  /* .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  .LoginPage_signupLink__ajxYw {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding: 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/login/LoginPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;;;;;;;;;;KAUG;;EAEH;IACE,gBAAgB;IAChB,YAAY;IACZ,cAAc;IACd,eAAe;IACf,UAAU;EACZ","sourcesContent":[".app {\n    padding: 20px;\n  }\n  \n  /* .modalOverlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  } */\n  \n  .signupLink {\n    background: none;\n    border: none;\n    color: #007bff;\n    cursor: pointer;\n    padding: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `LoginPage_app__HZso9`,
	"signupLink": `LoginPage_signupLink__ajxYw`
};
export default ___CSS_LOADER_EXPORT___;
