// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignIn_signinContainer__JlVEI {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .SignIn_formGroup__Gz9aj {
    margin-bottom: 15px;
  }
  
  .SignIn_label__aCNst {
    display: block;
    margin-bottom: 5px;
  }
  
  .SignIn_input__ncH0K {
    width: calc(100% - 10px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .SignIn_passwordContainer__GvDZ9 {
    position: relative;
  }
  
  .SignIn_passwordToggle__2emlf {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .SignIn_button__FTs96 {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .SignIn_button__FTs96:hover {
    background-color: #0056b3;
  }
  
  .SignIn_error__4hZ7a {
    color: red;
    margin-top: 5px;
  }

  .SignIn_noAccount__4ZW4k {
    margin-top: 10px;
  }
  
  .SignIn_signupLink__1yvyS {
    text-align: center;
    margin-top: 10px;
    text-decoration: none;
    color: #007bff;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/signin/SignIn.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,wBAAwB;IACxB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,2BAA2B;IAC3B,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,UAAU;IACV,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;IACrB,cAAc;EAChB","sourcesContent":[".signinContainer {\n    max-width: 400px;\n    margin: 0 auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    background-color: #f9f9f9;\n  }\n  \n  .formGroup {\n    margin-bottom: 15px;\n  }\n  \n  .label {\n    display: block;\n    margin-bottom: 5px;\n  }\n  \n  .input {\n    width: calc(100% - 10px);\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 3px;\n  }\n  \n  .passwordContainer {\n    position: relative;\n  }\n  \n  .passwordToggle {\n    position: absolute;\n    right: 5px;\n    top: 50%;\n    transform: translateY(-50%);\n    cursor: pointer;\n  }\n  \n  .button {\n    display: block;\n    width: 100%;\n    padding: 10px;\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    border-radius: 3px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .button:hover {\n    background-color: #0056b3;\n  }\n  \n  .error {\n    color: red;\n    margin-top: 5px;\n  }\n\n  .noAccount {\n    margin-top: 10px;\n  }\n  \n  .signupLink {\n    text-align: center;\n    margin-top: 10px;\n    text-decoration: none;\n    color: #007bff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signinContainer": `SignIn_signinContainer__JlVEI`,
	"formGroup": `SignIn_formGroup__Gz9aj`,
	"label": `SignIn_label__aCNst`,
	"input": `SignIn_input__ncH0K`,
	"passwordContainer": `SignIn_passwordContainer__GvDZ9`,
	"passwordToggle": `SignIn_passwordToggle__2emlf`,
	"button": `SignIn_button__FTs96`,
	"error": `SignIn_error__4hZ7a`,
	"noAccount": `SignIn_noAccount__4ZW4k`,
	"signupLink": `SignIn_signupLink__1yvyS`
};
export default ___CSS_LOADER_EXPORT___;
