import React, { useEffect, useState } from 'react';
import styles from './ArticleList.module.css';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService/ApiService';
import downloadArticle from '../../utils/downloadArticle';
import enedpoints from '../../common/endpoints';
import Loading from '../loading/Loading';
import Modal from '../modals/Modal';
import Alert from '../../utils/Alert';

const ArticleList = ({ handleClose, jid }) => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [articles, setArticles] = useState([]);
    const [loadMessage, setLoadMessage] = useState('Loading...');

    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    
    useEffect(() => {
        if(jid) {
            getArticlesByJobId(jid);
        }
    }, [jid]);
 
    const getArticlesByJobId = async(jid) => {
        try {
            const response = await ApiService.getDataById(enedpoints.articlesbyjob, jid);
            setArticles(response);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const generateArticles = async(jobId, topicLis=[]) => {
        try { 
            // console.log(jobId, topicLis);
            setLoadMessage('Articles is generating. Please wait...');
            setIsLoading(true);    
            const response = await ApiService.generateArticles(enedpoints.articlegenerate, jobId, topicLis);
            if(response) {
                Alert('Success',response.message, 'success');
                setIsLoading(false);
                handleClose();
            }
        } catch (error) {
          console.error(error);
        }
    };


    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                {isLoading ? <Loading  message={loadMessage}/> : null}
                <span className={styles.close} onClick={handleClose}>&times;</span>
                <h2>Generated Articles</h2>

                {articles.length === 0 ? <p>There is no article generated for this job.</p> :
                <div className={styles.tableContainer}>
                    <table className={styles.table}>
                    <thead>
                        <tr>
                            <th width="85%">Job Name</th>
                            <th width="15%">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {articles.map((article) => (
                            <tr key={article._id}>
                                <td>{article.topic}</td>
                                <td>
                                    <span className={styles.icon}>
                                        <span className={styles.view} title='View Article'><i className="fa fa-eye" onClick={() => navigate(`/articles/${article._id}`)}></i></span>
                                        <span className={styles.download} title='Download Article' onClick={() => downloadArticle(article)}><i className="fa fa-download"></i></span>
                                    </span>
                                    
                                </td>
                            </tr>
                        ))}
                        
                    </tbody>
                    </table>
                </div>
                }

                <button className={styles.generated} onClick={handleShowModal}>Generate Additional Article</button>
            </div>

            {showModal && <Modal handleClose={handleCloseModal}  jobId={jid} generateArticles={generateArticles}/>}
        </div>
    );
};

export default ArticleList;
