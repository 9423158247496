import React, { useEffect, useState } from 'react';
import styles from './SignUp.module.css';
import ApiService from '../../services/apiService/ApiService';
import enedpoints from '../../common/endpoints';
import Alert from '../../utils/Alert';
import Loading from '../loading/Loading';

const SignUp = ({ onClose, uid }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (uid) {
      getUserById(uid);
    }
  }, [uid]);

  useEffect(() => {
    setPasswordsMatch(password === confirmPassword && confirmPassword.length > 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPassword]);

  const getUserById = async(uid) => {
    try {
      const response = await ApiService.getDataById(enedpoints.users, uid);
      setUsername(response.username);
      setEmail(response.email);
      setRole(response.role);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  

    try {
    
      if (uid) {
        const data = {
          _id: uid,
          username,
          email,
          role,
        }
        const response = await ApiService.updateData(enedpoints.users, uid, data);
        Alert('Success',response.message, 'success');
      } else {
        if (!username.trim() || !email.trim() || !password.trim() || !role) {
          setError('Please fill in all fields.');
          return;
        }
    
        if (password !== confirmPassword) {
          setError('Passwords do not match.');
          return; 
        }
        
        const data = {
          username,
          email,
          role,
          password
        }
        const response = await ApiService.storeData(enedpoints.users, data);
        // console.log(response);
        Alert('Success',response.message, 'success');
      }
      // Reset form and error
      setUsername('');
      setEmail('');
      setPassword('');
      setError('');
      onClose();
    } catch (error) {
      console.error('Error during signup:', error);
      // setError(error.response.data.error);
      Alert('Failed',error.response.data.error, 'error');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.signupContainer}>
      {uid && isLoading && <Loading />}
      <span className={styles.close} onClick={onClose}>&times;</span>
      <h2> {!uid ? 'Create New Account' : 'Update Account'}</h2>
      {error && <p className={styles.error}>{error}</p>}

      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="username">
            Full Name:
          </label>
          <input
            className={styles.input}
            type="text"
            id="username"
            value={username}
            placeholder="Enter your full name"
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="email">
            Email:
          </label>
          <input
            className={styles.input}
            disabled={uid}
            type="email"
            id="email"
            value={email}
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        {!uid && (
          <>
            <div className={styles.formGroup}>
              <label className={styles.label} htmlFor="password">
                Password:
              </label>
              <div className={styles.passwordContainer}>
                <input
                  className={styles.input}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={password}
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span
                  className={styles.passwordToggle}
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (<i className="fa fa-eye"></i>) : (<i className="fa fa-eye-slash"></i>)}
                </span>
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.passwordContainer}>
                <input
                  className={styles.input}
                  type={showPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  value={confirmPassword}
                  placeholder="Confirm your password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <span
                  className={styles.passwordToggle}
                  onClick={togglePasswordVisibility}
                >
                  {passwordsMatch ? ( confirmPassword.length > 0 && <i className="fa fa-check"></i>) : ( confirmPassword.length > 0 && <i className="fa fa-times"></i>)}
                </span>
              </div>
            </div>
          </>
        )}
        
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="role">
            Role:
          </label>
          <select
            className={styles.select}
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="">Select Role</option>
            <option value="user">User</option>
            <option value="admin">Admin</option>
            <option value="superAdmin">Super Admin</option>
          </select>
        </div>
        {/* {error && <p className={styles.error}>{error}</p>} */}
        {uid ? (
          <button className={`${styles.button} ${styles.updateBtn}`} onClick={handleSubmit} type="submit">
            Update
          </button>
        ) : (
          <button className={`${styles.button} ${styles.saveBtn}`} onClick={handleSubmit} type="submit">
            Save
          </button>
        )}
       
        
      </form>
    </div>
  );
};

export default SignUp;
