import JSZip from 'jszip';

const downloadZip = (articles) => {
    const zip = new JSZip();
    articles.forEach((article, index) => {
        const content = article.article.map(section => `${section.title}\n${section.content}\n\n`).join('');
        zip.file(`${article.topic}.txt`, content);
    });
  
    zip.generateAsync({ type: 'blob' }).then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Articles.zip';
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
};

export default downloadZip;