import React, { useState, useEffect } from 'react';
import styles from './AnimatedWord.module.css';

const AnimatedWord = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const phrase = "WORD WISE AI";

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % phrase.length);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles.animatedWord}>
            {phrase.split('').map((letter, index) => (
                <span key={index} className={index === currentIndex ? styles.green : styles.white}>
                    {letter}
                </span>
            ))}
        </div>
    );
};

export default AnimatedWord;
