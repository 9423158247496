// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Articles_error__dizQz {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/article/Articles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ","sourcesContent":[".error {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `Articles_error__dizQz`
};
export default ___CSS_LOADER_EXPORT___;
