import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Sidebar.module.css';
// import image from '../../assets/logo.png';
import decodeToken from '../../utils/decodeToken';
import AnimatedWord from '../animatedword/AnimatedWord';

const Sidebar = () => {
    const [user, setUser] = useState({});
    const [prevPath, setPrevPath] = useState('jobs');
    const [cnt, setCnt] = useState(0);
    const [paths, setPaths] = useState({
        jobs: true,
        users: false,
        psbuilders: false,
        articles: false,
        scripts: false
    });

    useEffect(() => {
        setUser(() => decodeToken());
    }, [paths]);

    const handleRedirect = (path) => {
        if (path === prevPath) return;
        if (path === '/') path = 'jobs';
        setPaths({
            ...paths,
            [prevPath]: false,
            [path]: true
        })
        setPrevPath(path);
        setCnt((cnt + 1) % 3);
    }

    return (
        <div className={styles.sidebar}>
            <div>
                <div className={styles.logo}>
                    <AnimatedWord />
                </div>
                <ul className={styles.navLinks}>
                
                    <NavLink exact to="/" activeClassName={styles.active}>
                        <li onClick={() => handleRedirect('/')}>
                            <span><i className='fa fa-tachometer'></i></span>
                            <span>Dashboard</span>
                        </li>
                    </NavLink>

                    
                    <NavLink exact to="/jobs" activeClassName={styles.active}>
                        <li onClick={() => handleRedirect('jobs')} style={{ backgroundColor: paths.jobs ? '#898bf5': ''}}>
                            <span><i className='fa fa-tasks'></i></span>
                            <span>Jobs</span>
                        </li>
                    </NavLink>

                    <NavLink exact to="/articles" activeClassName={styles.active}>
                        <li onClick={() => handleRedirect('articles')} style={{ backgroundColor: paths.articles ? '#898bf5': ''}}>
                            <span><i className='fa fa-book'></i></span>
                            <span>Articles</span>
                        </li>
                    </NavLink>

                    <NavLink exact to="/scripts" activeClassName={styles.active}>
                        <li onClick={() => handleRedirect('scripts')} style={{ backgroundColor: paths.scripts ? '#898bf5': ''}}>
                            <span><i className='fa fa-file'></i></span>
                            <span>Scripts</span>
                        </li>
                    </NavLink>

                    {(user && user.role) === 'superAdmin' && (
                        <>
                            <NavLink exact to="/users" activeClassName={styles.active}>
                                <li onClick={() => handleRedirect('users')} style={{ backgroundColor: paths.users ? '#898bf5': ''}}>
                                    <span><i className='fa fa-users'></i></span>
                                    <span>Users</span>
                                </li>
                            </NavLink>

                            <NavLink exact to="/psbuilders" activeClassName={styles.active}>
                                <li onClick={() => handleRedirect('psbuilders')} style={{ backgroundColor: paths.psbuilders ? '#898bf5': ''}}>
                                    <span><i className='fa fa-gavel'></i></span>
                                    <span>PS Builders</span>
                                </li>
                            </NavLink>
                        </>
                        
                    )} 
                </ul>
            </div>
            <div className={styles.navFooter}>             
            </div>
        </div>
    );
};

export default Sidebar;
