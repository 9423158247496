import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import styles from './Job.module.css';
import ApiService from '../../services/apiService/ApiService';
import decodeToken from '../../utils/decodeToken';
import JobModal from './JobModal';
import ArticleList from './ArticleList';
import enedpoints from '../../common/endpoints';
import Alert from '../../utils/Alert';
import ConfirmationAlert from '../confirmation/ConfirmationAlert';
import Loading from '../loading/Loading';

const Job = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [pack, setPack] = useState({});
  const [jid, setJid] = useState(null);
  const [jName, setJName] = useState('');

  const [showJobModal, setShowJobModal] = useState(false);
  const handleShowJobModal = async(id) => {
    const permission = await jobCreatePermission();
    if(permission[0] || id){
      setJid(id);
      setShowJobModal(true);
    } else {
      Alert('Warnig', permission[1], 'warning');
      // console.log("Upgrade your package to create jobs");
    }
  }
  const handleCloseJobModal = () => setShowJobModal(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleShowConfirmationModal = (id, name) => {
      setJid(id);
      setJName(name);
      setShowConfirmationModal(true);
  }
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  const [showArticleModal, setShowArticleModal] = useState(false);
  const handleShowArticleModal = (id) => {
    setJid(id);
    setShowArticleModal(true);
  }
  const handleCloseArticleModal = () => setShowArticleModal(false);

  useEffect(() => {
    getAllJobByUser();
  }, [showJobModal, showConfirmationModal, showArticleModal]);

  const getAllJobByUser = async() => {
    try {
      let allJobs = [];
      const jobInfo = (decodeToken() && decodeToken().role) === 'superAdmin' ? await ApiService.getData(enedpoints.jobs) : await ApiService.getDataById(enedpoints.getjobbyuser, decodeToken().userId);
      // setIsLoading(false);
      if(jobInfo) {
        for(let i = 0; i < jobInfo.length; i++) {
          const psBuilder = await ApiService.getDataById(enedpoints.psbuilders, jobInfo[i].psbuilder);
          const articles = await ApiService.getDataById(enedpoints.articlesbyjob, jobInfo[i]._id);
          // console.log(articles);
          let data = {
            _id: jobInfo[i]._id,
            jobname: jobInfo[i].jobname,
            topic: jobInfo[i].topic,
            builder: psBuilder.name,
            product: jobInfo[i].product,
            createdAt: jobInfo[i].createdAt,
            lastArticle: articles.length ? articles[articles.length - 1].createdAt: null,
          }
          allJobs.push(data);
        }
      }
      
      // console.log(allJobs);
      setJobs(allJobs);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const jobCreatePermission = async () => {
    try {
      const currentUser = await ApiService.getDataById(enedpoints.users, decodeToken().userId);;
      if(!currentUser.packageId) {
        return [false, 'Please select a package'];
      }

      const newPackage = await ApiService.getDataById(enedpoints.packages, currentUser.packageId);
      setPack(newPackage);
      
      const today = new Date();
      const totalJob = jobs.filter(job => {
        const jobDate = new Date(job.createdAt);
        return jobDate.getFullYear() === today.getFullYear() && jobDate.getMonth() === today.getMonth() && jobDate.getDate() === today.getDate();
      });

      if(totalJob.length < newPackage.job_limit) {
        return [true, ''];
      } else {
        return [false, 'You have reached your limit of jobs for today.'];
      }
    } 
    catch (error) {
      console.error(error);
    }
    
  };

  const handleDeleteJob = async(id) => {
    try {
      const response = await ApiService.deleteData(enedpoints.jobs, id);
      Alert('Warning',response.message, 'warning');
      getAllJobByUser();
    } catch (error) {
      console.error(error);
    }
  }

  const generateArticles = async(id) => {
    try {     
        Alert('Info','Generating articles. Please wait...', 'info');  
        const response = await ApiService.getDataById(enedpoints.articlegenerate, id);

        if(response) {
            Alert('Success',response.message, 'success');
        }
    } catch (error) {
      console.error(error);
    }
};

  return (
    <div className='container'>
      <div className='content'>
        {isLoading ? <Loading /> : null}
        <div className='header'>
          <h1>Job List</h1>
          <span className={styles.error}></span>
          {  (<button className='addBtn' onClick={async () => await handleShowJobModal(null)}>
            New Job
          </button>)}
        </div>
        
        <div className='tableContainer'>
          <table className='table'>
            <thead>
              {jobs.length === 0 ? <p>There is no job created for this user.</p> :(
                <tr>
                 <th>Job Name</th>
                 <th>Number of Topics</th>
                 <th>Product</th>
                 <th>Builder</th>
                 <th>Save date</th>
                 <th>Last article generation date</th>
                 <th>Action</th>
               </tr>
              )}
             
            </thead>
            <tbody>
              {jobs && jobs.map((job, index) => (
                <tr key={index} className='row'>
                  <td>{job.jobname}</td>
                  <td data-tooltip-id="topics"
                  data-tooltip-content={job.topic.join(', ')}
                  data-tooltip-place="bottom" style={{ textAlign: 'center' }}>
                      {job.topic.length}
                      <Tooltip id="topics" />
                    </td>
                  <td>{job.product}</td>
                  <td>{job.builder}</td>
                  <td>{new Date(job.createdAt).toLocaleString()}</td>
                  <td>{job.lastArticle ? new Date(job.lastArticle).toLocaleString() : 'N/A'}</td>
                  <td>
                    <span className='actions'>
                      <span className='icon info' title='View Articles' onClick={() => handleShowArticleModal(job._id)}>
                          <i className="fa fa-book"></i>
                      </span>
                      <span className='icon edit' title='Edit Job' onClick={() => handleShowJobModal(job._id)}>
                          <i className="fa fa-edit"></i>
                      </span>
                      {
                          (decodeToken() && decodeToken().role) === 'superAdmin' && (
                              <>
                                  <span className='icon delete' title='Delete Job' onClick={() => handleShowConfirmationModal(job._id, job.jobname)}>
                                      <i className="fa fa-trash"></i>
                                  </span>
                              </>
                          )
                      }  
                    </span>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
     
      {showJobModal && <JobModal handleClose={handleCloseJobModal} id={jid} service={pack} />}
      {showArticleModal && <ArticleList handleClose={handleCloseArticleModal} jid={jid} />}
      {showConfirmationModal && (
          <div className='modalOverlay'>
              <ConfirmationAlert message={`Are you sure to delete "${jName}"?`} closeModel={handleCloseConfirmationModal} onConfirm={() => handleDeleteJob(jid)} />
          </div>
      )}
    </div>
  );
};

export default Job;
