import React, { useEffect, useState } from 'react';
import styles from './Modal.module.css';
import enedpoints from '../../common/endpoints';
import ApiService from '../../services/apiService/ApiService';
import decodeToken from '../../utils/decodeToken';

const Modal = ({ handleClose, jobId, generateArticles }) => {   
    const [info, setInfo] = useState({});
    const [topics, setTopics] = useState([]);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [created, setCreated] = useState(0);
    const [selectedTopics, setSelectedTopics] = useState([]);
    useEffect(() => {
        getInformation(jobId);
    }, [jobId]);

    useEffect(() => {
        if(selectedTopics.length === topics.length && topics.length !== 0) {
            document.getElementsByName('article_all')[0].checked = true;
        } else {
            document.getElementsByName('article_all')[0].checked = false;
        }
    }, [selectedTopics]);

    const selectAll = () => {
        const checkboxes = document.getElementsByName('article_all');
        if(checkboxes[0].checked) {
            if(info.package.articles_limit - (topics.length + created) >= 0) {    
                let tempTopics = [], cnt = 0;
                for(let i = 0; i < topics.length; i++) {
                    if(topics[i].created === false){
                        document.getElementsByName(`article_${i}`)[0].checked = true;
                        tempTopics.push(topics[i]);
                        cnt++;
                    }
                }
                setSelectedTopics(tempTopics);
                setMessage(`You can only select ${info.package.articles_limit - (cnt + created)} articles for this job.`);
            } else {
                setError(true);
                setMessage(`You have already reached maximum articles limit.`);
                checkboxes[0].checked = false;
            }
        } else {
            setSelectedTopics([]);
            for(let i = 0; i < topics.length; i++) {
                document.getElementsByName(`article_${i}`)[0].checked = false;
            }
            setMessage(`You can only select ${info.package.articles_limit - (created)} articles for this job.`);
        }
    }


    const getInformation = async(jobId) => {
        try {
            const job = await ApiService.getDataById(enedpoints.jobs, jobId);

            const user = await ApiService.getDataById(enedpoints.users, decodeToken().userId);
            const pack = await ApiService.getDataById(enedpoints.packages, user.packageId);
            const allArticles = await ApiService.getDataById(enedpoints.articlesbyjob, jobId);

            // How many articles created today
            const today = new Date();
            const availableArticlesLimit = allArticles.filter(article => {
                const articleDate = new Date(article.createdAt);
                return articleDate.getFullYear() === today.getFullYear() && articleDate.getMonth() === today.getMonth() && articleDate.getDate() === today.getDate();
            });
            setInfo({ job: job, user: user, package: pack, articles: availableArticlesLimit });      
            
            // Track if article is already created or not
            let newTopic = [], createdCount = 0;
            for(let i = 0; i < job.topic.length; i++) {
                let createFlag = false;
                if(allArticles.find(article => article.topic === job.topic[i])) { 
                    createFlag = true;
                    createdCount++;
                }
                
                newTopic.push({
                    topic: job.topic[i],
                    created: createFlag
                });
            }
            setTopics(newTopic);
            setCreated(createdCount);
            
            // Initially select articles
            if(pack.articles_limit >= availableArticlesLimit.length + (newTopic.length - createdCount)) {
                let tempTopics = [];
                for(let i = 0; i < newTopic.length; i++) {
                    if(!newTopic[i].created) {
                        tempTopics.push(newTopic[i]);
                        document.getElementsByName(`article_${i}`)[0].checked = true;
                        // createdCount++;
                    }
                }
                setSelectedTopics(tempTopics);
            }
            if(job.topic.length === createdCount) {
                setMessage(`You have already created all the articles for this job.`);
            }
            else {
                setMessage(pack.articles_limit - (newTopic.length - createdCount) > 0 ? `You can only select ${pack.articles_limit - (newTopic.length - createdCount) - createdCount} articles for this job.` : `You have already reached maximum articles limit.`);
            }
            
        } catch (error) {
            console.error(error);
        }
    }

    const handleCheckbox = (index) => {
        const checkboxes = document.getElementsByName(`article_${index}`);
        if(checkboxes[0].checked) {
            if(info.articles.length + selectedTopics.length < info.package.articles_limit) {
                setSelectedTopics([...selectedTopics, topics[index]]);
                setMessage(info.package.articles_limit - (selectedTopics.length + 1 + created) > 0 ? `You can only select ${info.package.articles_limit - (selectedTopics.length + 1 + created)} articles for this job.`: `You have already reached maximum articles limit.`);
            } else {
                setError(true);
                setMessage(`You have already reached maximum articles limit.`);
                checkboxes[0].checked = false;
            }
        } else {
            setError(false);
            setMessage(`You can only select ${info.package.articles_limit - (selectedTopics.length - 1 + created)} articles for this job.`);
            setSelectedTopics(selectedTopics.filter(topic => topic !== topics[index]));
        }   
    }

    const handleGenerate = async(jobId, selectedTopics) => {
        try {
            let newTopics = [];
            for(let i = 0; i < selectedTopics.length; i++) {
                newTopics.push(selectedTopics[i].topic);
            }
            handleClose();
            await generateArticles(jobId, newTopics);
            
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                    <h3>{'How many articles do you want to generate?'}</h3>
                    {/* <span className={styles.close} onClick={handleClose}>&times;</span> */}
                </div>

                <div className={styles.modalBody}>
                     { <p className={`${error ? styles.error: styles.show}`}>
                        {message}
                    </p>}
                    <table width={'100%'}>
                        <thead>
                            <tr>
                                <th><input type='checkbox' name='article_all' onClick={() => selectAll()}/> All</th>
                                <th>Topic</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {topics && topics.map((t, index) => (
                                <tr key={index}>
                                    <td><input name={`article_${index}`} disabled={t.created}  type="checkbox" onClick={() => handleCheckbox(index)} /></td>
                                    <td>{t.topic}</td>
                                    <td>{t.created ? "already created" : "to create"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className={styles.modalFooter}>
                    <button className={`${styles.modalBtn} ${styles.closeBtn}`} onClick={handleClose}>Close</button>
                    <button className={`${styles.modalBtn} ${styles.saveBtn}`} disabled={selectedTopics.length === 0} onClick={() => handleGenerate(jobId, selectedTopics)}>Confirm</button>
                </div>
             
            </div>
        </div>
    );
};

export default Modal;
