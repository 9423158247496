import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SignIn.module.css';
import AuthService from '../../services/apiService/AuthService';
import endpoinsts from '../../common/endpoints';
import Alert from '../../utils/Alert';

const SignIn = ({ onSignUpClick }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email.trim() || !password.trim()) {
        Alert('Please fill in all fields.', 'warning')
        return;
    }

    try {
        const response = await AuthService.login(endpoinsts.login, {email, password});
        if(response.token) {
          // Set token in local storage
          localStorage.setItem('token', response.token);
        
          // Reset form and error
          setEmail('');
          setPassword('');
          setError('');
          Alert('Success', 'Successfully logged in.', 'success');
          setTimeout(() => {
            navigate('/jobs');
          }, 1000)
          // navigate('/packages');
        } else {
            setError(response.message);
        }
    } catch (error) {
        Alert( 'Failed', error.response.data.error, 'error');
        // console.error('Error during authentication:', error);
    }
};


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.signinContainer}>
      <h2>Sign In</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="email">
            Email:
          </label>
          <input
            className={styles.input}
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="password">
            Password:
          </label>
          <div className={styles.passwordContainer}>
            <input
              className={styles.input}
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span
              className={styles.passwordToggle}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (<i className="fa fa-eye"></i>) : (<i className="fa fa-eye-slash"></i>)}
            </span>
          </div>
        </div>
        {error && <p className={styles.error}>{error}</p>}
        <button className={styles.button} onClick={handleSubmit} type="submit">
          Sign In
        </button>
        
      </form>
    </div>
  );
};

export default SignIn;
