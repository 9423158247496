// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
  }
  
  .toast {
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .success {
    background-color: #4caf50;
  }
  
  .error {
    background-color: #f44336;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/toast/Toast.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;EACf;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".toast-container {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    z-index: 9999;\n  }\n  \n  .toast {\n    color: #fff;\n    padding: 10px 20px;\n    border-radius: 5px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n  }\n  \n  .success {\n    background-color: #4caf50;\n  }\n  \n  .error {\n    background-color: #f44336;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
