import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ApiService from '../../services/apiService/ApiService';
import downloadArticle from '../../utils/downloadArticle';
import Toast from '../toast/Toast';
import enedpoints from '../../common/endpoints';
import Loading from '../loading/Loading';
import style from './Script.module.css'; 

const Script = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const [info, setInfo] = useState([]);
    const [edit, setEdit] = useState(false);

    const [toggle, setToggle] = useState([]);

    useEffect(() => {
        getScriptById(id);
    }, [id]);

    const getScriptById = async(id) => {
        try {
            const response = await ApiService.getDataById(enedpoints.scripts, id);
            setInfo(response);
            for (let i = 0; i < response.scripts.length; i++) {
                setToggle([...toggle, false]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleToggleInfo = (index) => {
        setToggle((prevToggle) => {
            const newToggle = [...prevToggle];
            newToggle[index] = !newToggle[index];
            return newToggle;
        });

    };

 
    return (
        <div className='container'>
            <div className='content'>
                {isLoading && <Loading />}
                <div className='header'>  
                    <h2>{info && info.topic ? info.topic: ""}</h2>
                    
                    <span className='actions'>
                        <span className='icon download' title='Download Article' onClick={() => downloadArticle(info, 'script')}>
                            <i className="fa fa-download"></i>
                        </span>
                        {/* <span className='icon edit' title='Edit Article'>
                            <i className="fa fa-edit"></i>
                        </span> */}
                        {/* {edit && <span className='icon edit' title='Cancel'>
                            <i className="fa fa-times"></i>
                        </span>} */}
                    
                    </span>

                </div>

                {info && info.scripts ? info.scripts.map((item, index) => (
                    edit ? <div key={index}>
                        {/* <input className={styles.title}
                            value={item.title}
                            onChange={(e) => {
                                const newArticle = [...article.article];  
                                newArticle[index].title = e.target.value;
                                setArticle({ ...article, article: newArticle });  
                            }}
                        />
                        <textarea className={styles.content}
                            value={item.content}
                            onChange={(e) => {
                                const newArticle = [...article.article];  
                                newArticle[index].content = e.target.value;
                                setArticle({ ...article, article: newArticle });
                            }}
                        /> */}
                    </div>:
                    <div key={index}>
                        <div className={style.line}>
                            <span>Video {index + 1} : {item.title ? item.title : ""}</span>
                            <i className={`fa fa-chevron-${toggle[index] ? "up" : "down"}`} onClick={() => handleToggleInfo(index)}></i>
                        </div>
                        {toggle[index] && <div className={style.content}>
                            {item.content && item.content.split('\n').map((line, lineIndex) => (
                                <p key={lineIndex}>{line}</p>
                            ))}
                        </div>}
                        
                    </div>
                )): "This article is not generated yet"}

                {/* {edit && (
                <button className='view' onClick={handleSave}>Save</button>
                )}
                {showToast && <Toast status={'success'} message={toastMessage} />} */}
                
            </div>
        </div>
    );
};

export default Script;
