import React, { useEffect, useState } from 'react';
import Loading from '../loading/Loading';
import Modal from './Modal';
import ApiService from '../../services/apiService/ApiService';
import enedpoints from '../../common/endpoints';
import decodeToken from '../../utils/decodeToken';
import { useNavigate } from 'react-router-dom';
import Alert from '../../utils/Alert';

const ScriptList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [scripts, setScripts] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    getAllScripts();
  }, [showModal]);

  const getAllScripts = async () => {
    try {
      const response = decodeToken() && decodeToken().role === 'superAdmin' ? await ApiService.getData(enedpoints.scripts) : await ApiService.getDataById(enedpoints.getscriptbyuser, decodeToken().userId);
      setScripts(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await ApiService.deleteData(enedpoints.scripts, id);
      getAllScripts();
      Alert('success',response.message, 'success')
    } catch (error) {
      console.error('Error deleting script:', error);
      Alert('Failed', error.response.data.error, 'error');
    }
  }


  return (
    <div className='container'>
      <div className='content'>
        {isLoading ? <Loading /> : null}
        <div className='header'>
          <h1>Script List</h1>
          
          {  (<button className='addBtn' onClick={handleShowModal}>
            New Script
          </button>)}
        </div>
        
        <div className='tableContainer'>
          <table className='table'>
            <thead>
              {scripts.length === 0 ? <p>There is no script created for this user.</p> :(
                <tr>
                 <th>#</th>
                 <th>Script Name</th>
                 <th>Save date</th>
                 <th style={{ textAlign: 'right' }}>Action</th>
               </tr>
              )}
             
            </thead>
            <tbody>
              {scripts && scripts.map((script, index) => (
                <tr key={index} className='row'>
                  <td>{index + 1}</td>
                  <td>{script.topic}</td>
                  <td>{new Date(script.createdAt).toLocaleString()}</td>
                  <td>
                    <span className='actions'>
                      <span className='icon view' title='View Script' onClick={() => navigate(`/scripts/${script._id}`)}>
                          <i className="fa fa-eye"></i>
                      </span>
                      <span className='icon edit' title='Edit Script'>
                          <i className="fa fa-edit"></i>
                      </span>
                      {
                          (decodeToken() && decodeToken().role) === 'superAdmin' && (
                              <>
                                  <span className='icon delete' title='Delete Script' onClick={() => handleDelete(script._id)}>
                                      <i className="fa fa-trash"></i>
                                  </span>
                              </>
                          )
                      }  
                    </span>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showModal && <Modal handleClose={handleCloseModal} />}
     
    </div>
  );
};

export default ScriptList;
