import React from 'react';
import './Loading.css';

const Loading = ({ message }) => (
  <div className="loading-overlay">
    <div className="loading-message">{message ? message : 'Loading...'}</div>
    <div className="spinner"></div>
  </div>
);

export default Loading;