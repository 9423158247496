import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = async (url, method = 'GET', data = null) => {
  try {
    const token = url !== 'login' ? localStorage.getItem('token') : null;
    
    const headers = {
      'Content-Type': 'application/json',
      "ngrok-skip-browser-warning": "69420",
      Authorization: `Bearer ${token}`,
    };
    let requestOptions = {};

    if(data) {
      requestOptions = {
        method,
        url: `${BASE_URL}/${url}`,
        headers,
        data,
      };
    } else {
      requestOptions = {
        method,
        url: `${BASE_URL}/${url}`,
        headers,
      };
    }

    const response = await axios(requestOptions);
    return response.data;
  } catch (error) {
    console.error('API Error:', error.message);
    if (error.response.data.message === 'Invalid token') {
      localStorage.removeItem('token');
    }
    throw error;
  }
};

export default api;
