import api from './api';

const AuthService = {
  login: async (endpoint, data) => {
    try {
      const response = await api(endpoint, 'POST', data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  logout: async (endpoint) => {
    try {
      const response = await api(endpoint);
      return response;
    } catch (error) {
      throw error;
    }
  }
};

export default AuthService;
