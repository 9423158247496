// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AnimatedWord_animatedWord__eoyGf {
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    animation: AnimatedWord_blink__NiOl0 1s infinite;
    font-family: 'Times New Roman', Times, serif;
    padding: 10px 7px;
}

.AnimatedWord_green__uzz2Z {
    color: black;
}

.AnimatedWord_white__AQk9g {
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/animatedword/AnimatedWord.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,eAAe;IACf,iBAAiB;IACjB,gDAA4B;IAC5B,4CAA4C;IAC5C,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".animatedWord {\n    display: inline-block;\n    font-size: 24px;\n    font-weight: bold;\n    animation: blink 1s infinite;\n    font-family: 'Times New Roman', Times, serif;\n    padding: 10px 7px;\n}\n\n.green {\n    color: black;\n}\n\n.white {\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animatedWord": `AnimatedWord_animatedWord__eoyGf`,
	"blink": `AnimatedWord_blink__NiOl0`,
	"green": `AnimatedWord_green__uzz2Z`,
	"white": `AnimatedWord_white__AQk9g`
};
export default ___CSS_LOADER_EXPORT___;
