const enedpoints = {
    // public
    login: 'login', // user login

    // user
    users: 'users', // get all users
    subscribe: 'users/subscribe', // subscribe to a package
    logout: 'users/logout', //  user logout

    // package
    packages: 'packages', // get all packages

    // job
    jobs: 'jobs', // get all jobs
    getjobbyuser: 'jobs/user', // get all jobs by user

    // article
    articles: 'articles', // get all articles
    articlesbyuser: 'articles/user', // get all articles by user
    articlesbyjob: 'articles/job', // get all articles by job
    articlegenerate: 'articles/generate', // generate articles for a given job_id

    // psbuilder
    psbuilders: 'psbuilders', // get all psbuilders

    routepsbuildersadd: '/psbuilders/add', // add psbuilder
    routepsbuildersedit: '/psbuilders/edit', // edit psbuilder

    // script
    scripts: 'scripts', // 
    getscriptbyuser: 'scripts/user', // get all scripts by user

}

export default enedpoints