import React from 'react';
import { Outlet } from 'react-router-dom';
import '../../common/Style.css';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import styles from './HomePage.module.css';

const HomePage = () => {
    return (
        <div className={styles.app}> 
            <div className={styles.sidebar}>
                <Sidebar /> 
            </div>  
            
            <div className={styles.container}>
                <Navbar />
                <Outlet />
            </div>
        </div>
    );
};

export default HomePage;