import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Navbar.module.css';
import decodeToken from '../../utils/decodeToken';
import AuthService from '../../services/apiService/AuthService';
import enedpoints from '../../common/endpoints';
import Alert from '../../utils/Alert';
import DisplayCard from '../package/DisplayCard';
import ModalForm from '../package/ModalForm';

const Navbar = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [popup, setPopup] = useState(false);
    const popupRef = useRef(null); 
    const infoRef = useRef(null);

    const [showCard, setShowCard] = useState(false);
    const handleShowCard = () => {
        setPopup(false);
        setShowCard(true);
    }
    const handleCloseCard = () => setShowCard(false);

    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => {
        setPopup(false);
        setShowModal(true);
    }
    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        setUser(() => decodeToken()); 

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (infoRef.current && infoRef.current.contains(event.target)) {
            return
        }
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setPopup(false);
        }
    };

    const handleLogout = async () => {
        try {
            const response = await AuthService.logout(enedpoints.logout);
            if(response) {
                localStorage.removeItem("token");
                navigate("/login", { replace: true });
            }
        } catch (error) {
            Alert(error.response.data.error, 'error');
            console.error('Error during logout:', error);
        }
    };

    const handlePopup = () => {
        setPopup(!popup);
    }

    return (
        <>
            <div className={styles.navbar}>

                <div className={styles.logo}>
                    {/* <img src={image} alt="Logo" /> */}
                   
                </div>
               
                <div className={styles.dropdown}>
                    <div className={styles.userInfo} onClick={handlePopup} ref={infoRef}>
                        <span className={styles.userIcon}><i className="fa fa-user"></i></span>
                        <span className={styles.usernameText}>{user && user.userName ? user.userName : 'User'}</span>
                    </div>  
                </div>
            </div>

            {popup && (
                <div className={styles.popup} ref={popupRef}>
                    <ul>
                        <li><span className={styles.icon}><i className='fa fa-user'></i></span> <span className={styles.text}>Profile</span></li>
                        <li><span className={styles.icon}><i className='fa fa-cogs'></i></span> <span className={styles.text}>Settings</span></li>
                        <li onClick={handleShowCard}><span className={styles.icon}><i className='fa fa-diamond'></i></span> <span className={styles.text}>Packages</span></li>
                        { user && user.role === 'superAdmin' && <li onClick={handleShowModal}><span className={styles.icon}><i className='fa fa-plus'></i></span> <span className={styles.text}>Add Package</span></li>}
                        
                        <li><span className={styles.icon}><i className='fa fa-sign-out'></i></span> <span className={styles.text} onClick={handleLogout}>Logout</span></li>
                    </ul>
                 
                </div>
            )}
            
            {showModal && <ModalForm handleClose={handleCloseModal}></ModalForm>}
            {showCard && <DisplayCard handleClose={handleCloseCard}></DisplayCard>}
        </>
    );
};

export default Navbar;
