import React from 'react';
import 'sweetalert2/dist/sweetalert2.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PSBuilder from './components/psbuilder/PSBuilder';
import Job from './components/job/Job';
import Article from './components/article/Article';
import Package from './components/package/Package';
import LoginPage from './pages/login/LoginPage';
import HomePage from './pages/home/HomePage';
import User from './components/user/User';
import Articles from './components/article/Articles';
import BuilderList from './components/psbuilder/BuilderList';
import ScriptList from './components/script/ScriptList';
import Script from './components/script/Script';

// home protected route
const HomePageProtected = () => {
  return <Navigate to="/login" replace />;
}

const LoginProtected = () => {
  const isLoggedIn = () => {
    const token = localStorage.getItem("token");
    return !!token; 
  };

  if (isLoggedIn()) {
    return <Navigate to="/jobs" replace />;
  }

  return (
    <>
      <LoginPage />
    </>
  );
};

const RouterProtection = ({ children }) => {
  const isLoggedIn = () => {
    const token = localStorage.getItem("token"); 
    return !!token; 
  };

  if (!isLoggedIn()) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      {children}
    </>
  );
}

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginProtected />} />
        <Route path="/" element={<HomePage />}>
          <Route exect path="" element={<HomePageProtected />} />
          <Route exect path="users" element={<RouterProtection children={<User />} />} />
          <Route exect path="jobs" element={ <RouterProtection children={<Job />} />} />
          <Route exect path="scripts" element={<RouterProtection children={<ScriptList />} />}/>
          <Route exect path="scripts/:id" element={<RouterProtection children={<Script />} />}/>
          <Route exect path="articles" element={<RouterProtection children={<Articles />} />}/>
          <Route exect path="articles/:id" element={<RouterProtection children={<Article />} />}/>
          <Route exect path="psbuilders" element={<RouterProtection children={<BuilderList />} />}/>
          <Route exect path="/psbuilders/add" element={<RouterProtection children={<PSBuilder />} />}/>
          <Route exect path="/psbuilders/edit/:id" element={<RouterProtection children={<PSBuilder />} />}/>
          <Route exect path="packages" element={<RouterProtection children={<Package />} />}/>
          <Route exect path="*" element={<HomePageProtected />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
