import React, { useEffect, useState } from 'react';
import styles from '../modals/Modal.module.css';
import enedpoints from '../../common/endpoints';
import ApiService from '../../services/apiService/ApiService';
import decodeToken from '../../utils/decodeToken';
import Alert from '../../utils/Alert';
import Loading from '../loading/Loading';

const Modal = ({ handleClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [scriptTitle, setScriptTitle] = useState('');
    const handleGenerateScript = async() => {
        try {
            const token = localStorage.getItem('token');
            const decodedToken = decodeToken(token);
            setIsLoading(true);
            const response = await ApiService.storeData(enedpoints.scripts, {title: scriptTitle, userId: decodedToken.userId});
            if(response) {
                setIsLoading(false);
                Alert('Success', response.message, 'success');
                handleClose();
            }
        }
        catch(error) {
            console.error(error);
            setIsLoading(false);
            Alert('Failed', error.response.data.error, 'error');
        }
    };

    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                    <h3>{'Create Script'}</h3>
                    <span className={styles.close} onClick={handleClose}>&times;</span>
                </div>

                <div className={styles.modalBody} style={{position: 'relative'}}>
                {isLoading ? <Loading message={'Generating Script...'} /> : null} 
                    <div className={styles.row}>
                        <input style={{width: '95%'}} type="text" id="scriptTitle" placeholder="Script Title" onChange={(e) => setScriptTitle(e.target.value)}/>
                        <button style={{marginTop: '10px'}} className={`${styles.modalBtn} ${styles.saveBtn}`} onClick={handleGenerateScript}>Generate Script</button>
                    </div>
                </div>

                {/* <div className={styles.modalFooter}>
                    <button className={`${styles.modalBtn} ${styles.closeBtn}`} onClick={handleClose}>Close</button>
                    <button className={`${styles.modalBtn} ${styles.saveBtn}`} disabled={selectedTopics.length === 0}>Confirm</button>
                </div> */}
             
            </div>
        </div>
    );
};

export default Modal;
