import api from './api';

const ApiService = {
  getData: async (endpoint) => {
    try {
      const response = await api(endpoint);
      return response;
    } catch (error) {
      throw error;
    }
  },

  getDataById: async (endpoint, id) => {
    try {
      const response = await api(`${endpoint}/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  storeData: async (endpoint, data) => {
    try {
      const response = await api(endpoint, 'POST', data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  updateData: async (endpoint, id, data) => {
    try {
      const response = await api(`${endpoint}/${id}`, 'PUT', data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  deleteData: async (endpoint, id) => {
    try {
      const response = await api(`${endpoint}/${id}`, 'DELETE');
      return response;
    } catch (error) {
      throw error;
    }
  }, 

  generateArticles: async (endpoint, id, data) => {
    try {
      const response = await api(`${endpoint}/${id}`, 'POST', data);
      return response;
    } catch (error) {
      throw error;
    }
  }
};

export default ApiService;
