import React, { useEffect, useState } from 'react';
import styles from './Article.module.css'; 
import { useParams } from 'react-router-dom';
import ApiService from '../../services/apiService/ApiService';
import downloadArticle from '../../utils/downloadArticle';
import Toast from '../toast/Toast';
import enedpoints from '../../common/endpoints';
import Loading from '../loading/Loading';

const Article = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [article, setArticle] = useState([]);
  const [edit, setEdit] = useState(false);

  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    getArticleById(id);
  }, [id]);


  const getArticleById = async(id) => {
    try {
      const response = await ApiService.getDataById(enedpoints.articles, id);
      setArticle(response);
      setIsLoading(false);

    } catch (error) {
      console.error(error);
    }
  };

  const editModeEnable = () => {
    setEdit(true);
    setToastMessage('');
    setShowToast(false);
  }
  const editModeDisable = () => {
    setEdit(false);    
  }

  const handleSave = async() => {
    try {
      let data = {
        topic: article.topic,
        article: article.article
      }
      let i = 0;
      for(const val of data.article) {

        if( val.title === '' || (val.title === '' && val.content === '')) {
          data.article.splice(i, 1); 
        }
        i++;
      };

      const response = await ApiService.updateData(enedpoints.articles, id, data);
      editModeDisable();
      setToastMessage(response.message);
      setShowToast(true);
    } catch (error) {
      console.error(error);
      setToastMessage(error.response.data.error);
      setShowToast(true);
    }
  }

  return (
    <div className='container'>
      <div className='content'>
        {isLoading && <Loading />}
        <div className='header'>  
          <h2>{article? article.topic: ""}</h2>
          <span className={styles.error}></span>
          <span className='actions'>
            <span className='icon download' title='Download Article' onClick={() => downloadArticle(article, 'article')}>
                <i className="fa fa-download"></i>
            </span>
            <span className='icon edit' title='Edit Article' onClick={editModeEnable}>
                <i className="fa fa-edit"></i>
            </span>
            {edit && <span className='icon edit' title='Cancel' onClick={editModeDisable}>
                <i className="fa fa-times"></i>
            </span>}
          
          </span>

        </div>

        {article && article.article ? article.article.map((item, index) => (
            edit ? <div key={index}>
                <input className={styles.title}
                    value={item.title}
                    onChange={(e) => {
                        const newArticle = [...article.article];  
                        newArticle[index].title = e.target.value;
                        setArticle({ ...article, article: newArticle });  
                    }}
                />
                <textarea className={styles.content}
                    value={item.content}
                    onChange={(e) => {
                        const newArticle = [...article.article];  
                        newArticle[index].content = e.target.value;
                        setArticle({ ...article, article: newArticle });
                    }}
                />
            </div>:
            <div key={index}>
                <h3>{item.title ? item.title : ""}</h3>
                <p>{item.content}</p>
            </div>
        )): "This article is not generated yet"}

        {edit && (
          <button className='view' onClick={handleSave}>Save</button>
        )}
        {showToast && <Toast status={'success'} message={toastMessage} />}
        
      </div>
    </div>
  );
};

export default Article;
