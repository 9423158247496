// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Script_line__yYJf3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    background-color: #b1d3f6;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
}

.Script_line__yYJf3 i {
    cursor: pointer;
}

.Script_content__G6qQe {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color: #9cfbb2; 
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/script/Script.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,sHAAsH;IACtH,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".line {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font-weight: bold;\n    background-color: #b1d3f6;\n    margin-bottom: 10px;\n    padding: 10px;\n    border-radius: 5px;\n}\n\n.line i {\n    cursor: pointer;\n}\n\n.content {\n    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;\n    background-color: #9cfbb2; \n    margin-bottom: 10px;\n    padding: 10px;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": `Script_line__yYJf3`,
	"content": `Script_content__G6qQe`
};
export default ___CSS_LOADER_EXPORT___;
