import React from 'react';
import styles from './LoginPage.module.css';
import SignIn from '../../components/signin/SignIn';

const LoginPage = () => {

  return (
    <div className={styles.app}>
      <h1> </h1>
      <SignIn />
    </div>
  );
};

export default LoginPage;
