import React, { useEffect, useState } from 'react';
import styles from './Card.module.css';
import ApiService from '../../services/apiService/ApiService';
import enedpoints from '../../common/endpoints';
import decodeToken from '../../utils/decodeToken';
import Alert from '../../utils/Alert';
import ModalForm from './ModalForm';

const Card = ({ handleClose, pack }) => {
  const [user, setUser] = useState({});
  const [newPack, setNewPack] = useState(pack);
  useEffect(() => {
    getUserInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [packageId, setPackageId] = useState('');
  const handleShowModal = (pid) => {
    setPackageId(pid);
    setShowModal(true);
  }
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    getPackageById(newPack._id);
  }, [showModal])

  const getUserInfo = async () => {
    try {
      const response = await ApiService.getDataById(enedpoints.users, decodeToken().userId);
      setUser(response);
      
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const getPackageById = async (packageId) => {
    try {
      const response = await ApiService.getDataById(enedpoints.packages, packageId);
      setNewPack(response);
    } catch (error) {
      console.error('Error fetching package:', error);
    }
  }

  const handleSubscribe = async () => {
    try {
      const response = await ApiService.updateData(enedpoints.subscribe, decodeToken().userId, {packageId: pack._id});
      handleClose();
      Alert('success',response.message, 'success')
    } catch (error) {
      console.error('Error subscribing:', error);
      Alert(error.response.data.error, 'error');
    }
  }

  const handleDelete = async () => {
    try {
      const response = await ApiService.deleteData(enedpoints.packages, pack._id);
      handleClose();
      Alert('success',response.message, 'success')
    } catch (error) {
      console.error('Error deleting package:', error);
      Alert(error.response.data.error, 'error');
    }
  }
  return (
    <>
      <div className={`${user.packageId === newPack._id ? styles.active : styles.card}`}>
        <div className={styles.circle}>{newPack ? newPack.package_name : ''}</div>
        <div className={styles.text}>
          <span className={styles.row}>
            <span>Job limit per day: </span> <span>{newPack ? newPack.job_limit > 99 ? 'Unlimited' : newPack.job_limit : ''}</span> 
          </span>
          <span className={styles.row}>
            <span>Article limit per day: </span> <span>{newPack ? newPack.articles_limit > 99 ? 'Unlimited' : newPack.articles_limit : ''}</span> 
          </span>
          <span className={styles.row}>
            <span>Word limit per day: </span> <span>{newPack ? newPack.word_limit >= 5000 ? 'Unlimited' : newPack.word_limit : ''}</span> 
          </span>
          
        </div>
        <div>

        </div>
        {user && user.role === 'superAdmin' ? (
           <div className={styles.row}>
           <button type="button" className={styles.saveBtn} onClick={() => handleShowModal(pack._id)}>Update</button>
           <button type="button" className={styles.deleteBtn} onClick={handleDelete}>Delete</button>
         </div>
        ) : (<button className={styles.saveBtn} onClick={handleSubscribe}>Subscribe</button>)}
        
      </div>

      {showModal && <ModalForm handleClose={handleCloseModal} packageId={packageId}></ModalForm>}
    </>
   
  );
};

export default Card;
