// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Article_error__BSi9E {
  color: red;
}

.Article_title__dtwuY {
  padding: 20px;
  margin-bottom: 10px;
  width: calc(100% - 40px);
}

.Article_content__pT5p\\+ {
  padding: 20px;
  width: calc(100% - 40px);
  min-height: 100px; /* Set a minimum height if needed */
  resize: vertical;
}`, "",{"version":3,"sources":["webpack://./src/components/article/Article.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,wBAAwB;EACxB,iBAAiB,EAAE,mCAAmC;EACtD,gBAAgB;AAClB","sourcesContent":[".error {\n  color: red;\n}\n\n.title {\n  padding: 20px;\n  margin-bottom: 10px;\n  width: calc(100% - 40px);\n}\n\n.content {\n  padding: 20px;\n  width: calc(100% - 40px);\n  min-height: 100px; /* Set a minimum height if needed */\n  resize: vertical;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `Article_error__BSi9E`,
	"title": `Article_title__dtwuY`,
	"content": `Article_content__pT5p+`
};
export default ___CSS_LOADER_EXPORT___;
