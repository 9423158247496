import React, { useEffect, useState } from 'react';
import styles from './ModalForm.module.css';
import ApiService from '../../services/apiService/ApiService';
import enedpoints from '../../common/endpoints';
import Alert from '../../utils/Alert';
import Loading from '../loading/Loading';

const ModalForm = ({ handleClose, packageId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState([
    { package_name: '', job_limit: '', articles_limit: '', word_limit: '' }
  ]);

  useEffect(() => {
    if(packageId) getPackageById(packageId);
  }, [packageId]);

  const getPackageById = async (packageId) => {
    try {
      const response = await ApiService.getDataById(enedpoints.packages, packageId);
      let data = {
        _id: response._id,
        package_name: response.package_name,
        job_limit: response.job_limit,
        articles_limit: response.articles_limit,
        word_limit: response.word_limit
      };
      setServices([data]);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching package:', error);
    }
  };

  const handleChange = (e, index) => {
    let { name, value } = e.target;
    if (name !== 'package_name') {
      value = parseInt(value);
    }
    const updatedServices = [...services];
    updatedServices[index][name] = value;
    setServices(updatedServices);
  };

  const addService = () => {
    setServices([...services, { package_name: '', job_limit: '', articles_limit: '', word_limit: '' }]);
  };

  const removeService = (index) => {
    const updatedServices = [...services];
    updatedServices.splice(index, 1);
    setServices(updatedServices);
  };

  const handleSubmit = async () => {
    // e.preventDefault();
    // Simulated authentication
    
    try {
      if(packageId) {
        services[0]._id = packageId;
        const response = await ApiService.updateData(enedpoints.packages, packageId, services[0]);
        Alert('Success',response.message, 'success');
      } else {
        for(let service of services) {
          await ApiService.storeData(enedpoints.packages, service); 
        }
        // const response = await ApiService.storeData(enedpoints.packages, services);
        Alert('Success', 'Package created successfully', 'success');
      }
      
      // Reset form and error
      setServices([{ package_name: '', job_limit: '', articles_limit: '', word_limit: '' }]);
    
      handleClose();
    } catch (error) {
      console.error('Error during signup:', error);
      Alert('Failed', error.response.data.error, 'error');
    }
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        {packageId && isLoading && <Loading />}
        <span className={styles.close} onClick={handleClose}>&times;</span>
        <h2> {packageId ? 'Update Package' : ' Create Package'}</h2>

        <form>
      
          <div className={styles.services}>
            {services.map((service, index) => (
              <div key={index} className={styles.serviceContent}>
                <div className={styles.full}>
                  <input
                    type="text"
                    id={`package_name_${index}`}
                    name="package_name"
                    value={service.package_name}
                    placeholder='Package Name'
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
               
                <div className={styles.row}>
                  <input
                    type="number"
                    id={`job_limit_${index}`}
                    className={styles.fifty}
                    name="job_limit"
                    value={service.job_limit}
                    placeholder='Job Limit'
                    onChange={(e) => handleChange(e, index)}
                  />
                  <input
                    type="number"
                    id={`articles_limit_${index}`}
                    className={styles.fifty}
                    name="articles_limit"
                    value={service.articles_limit}
                    placeholder='Articles Limit'
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
                <div className={styles.row}>
                  <input
                    type="number"
                    id={`word_limit_${index}`}
                    className={styles.fifty}
                    name="word_limit"
                    value={service.word_limit}
                    placeholder='Word Limit'
                    onChange={(e) => handleChange(e, index)}
                  />

                  <div className={styles.iconPart}>
                   
                    {
                      index ? <span className={styles.deleteBtn} onClick={() => removeService(index)}><i className='fa fa-trash'></i></span> : ''
                    }
                  </div>

                </div>
               
              </div>
            ))}
            
          </div>
          <div className={styles.row}>
            {!packageId && <button type="button" className={styles.addBtn} onClick={addService}>Add</button>}
            <button type="button" className={packageId ? styles.updateBtn : styles.saveBtn} onClick={handleSubmit}>{packageId ? 'Update' : 'Save'}</button>
          </div>
          
        </form>
       
      </div>
    </div>
  );
};

export default ModalForm;
