// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PSBuilder.module.css */

.PSBuilder_psBuilderContainer__s0jJV {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.PSBuilder_header__x33vY {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 20px; */
    /* width: 100%; */
    margin: 0 auto ;
    width: 80%;
  }

.PSBuilder_tableContainer__5MlPK {
    width: 80%;
}

.PSBuilder_table__in4V5 {
    width: 100%;
    border-collapse: collapse;
}

.PSBuilder_table__in4V5 th,
.PSBuilder_table__in4V5 td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
}

.PSBuilder_table__in4V5 th {
    background-color: #f2f2f2;
}

.PSBuilder_table__in4V5 textarea {
    width: 100%;
    height: 100px;
    overflow: hidden; /* Prevents scrolling */
    border: none;
}

.PSBuilder_table__in4V5 input[type="number"] {
    width: 100px;
    border: none;
    outline: none;
}

button {
    padding: 10px 20px;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

.PSBuilder_saveBtn__KWPfB {
    background-color: #4CAF50;
}

.PSBuilder_saveBtn__KWPfB:hover {
    background-color: #3e8e41;
}

.PSBuilder_editBtn__gV4Bf {
    background-color: #007bff;
}

.PSBuilder_editBtn__gV4Bf:hover {
    background-color: #0069d9;
}`, "",{"version":3,"sources":["webpack://./src/components/psbuilder/PSBuilder.module.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,8BAA8B;IAC9B,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;IACjB,eAAe;IACf,UAAU;EACZ;;AAEF;IACI,UAAU;AACd;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;;IAEI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB,EAAE,uBAAuB;IACzC,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* PSBuilder.module.css */\n\n.psBuilderContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    padding: 20px;\n}\n\n.header {\n    display: flex;\n    justify-content: center;\n    justify-content: space-between;\n    align-items: center;\n    /* margin-bottom: 20px; */\n    /* width: 100%; */\n    margin: 0 auto ;\n    width: 80%;\n  }\n\n.tableContainer {\n    width: 80%;\n}\n\n.table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.table th,\n.table td {\n    border: 1px solid #dddddd;\n    padding: 8px;\n    text-align: left;\n}\n\n.table th {\n    background-color: #f2f2f2;\n}\n\n.table textarea {\n    width: 100%;\n    height: 100px;\n    overflow: hidden; /* Prevents scrolling */\n    border: none;\n}\n\n.table input[type=\"number\"] {\n    width: 100px;\n    border: none;\n    outline: none;\n}\n\nbutton {\n    padding: 10px 20px;\n    color: #ffffff;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    width: 100%;\n}\n\n.saveBtn {\n    background-color: #4CAF50;\n}\n\n.saveBtn:hover {\n    background-color: #3e8e41;\n}\n\n.editBtn {\n    background-color: #007bff;\n}\n\n.editBtn:hover {\n    background-color: #0069d9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"psBuilderContainer": `PSBuilder_psBuilderContainer__s0jJV`,
	"header": `PSBuilder_header__x33vY`,
	"tableContainer": `PSBuilder_tableContainer__5MlPK`,
	"table": `PSBuilder_table__in4V5`,
	"saveBtn": `PSBuilder_saveBtn__KWPfB`,
	"editBtn": `PSBuilder_editBtn__gV4Bf`
};
export default ___CSS_LOADER_EXPORT___;
