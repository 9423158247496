import React, { useEffect, useState } from 'react';
// import styles from './Job.module.css';
import ApiService from '../../services/apiService/ApiService';
import decodeToken from '../../utils/decodeToken';
import { useNavigate } from 'react-router-dom';
import Alert from '../../utils/Alert';
import ConfirmationAlert from '../confirmation/ConfirmationAlert';
import enedpoints from '../../common/endpoints';
import Loading from '../loading/Loading';
// import Alert from '../../utils/Alert';

const BuilderList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [psbuilder, setPsbuilder] = useState([]);
    const [pid, setPid] = useState(null);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const handleShowConfirmationModal = (id) => {
        setPid(id);
        setShowConfirmationModal(true);
    }
    const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

    useEffect(() => {
        getAllPSBuilders();
    }, []);

    const getAllPSBuilders = async () => {
        try {
            const response = await ApiService.getData(enedpoints.psbuilders);
            setPsbuilder(response);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeletePSBuilder = async(id) => {
        try {
            const response = await ApiService.deleteData(enedpoints.psbuilders, id);
            Alert('Warning',response.message, 'warning');
            getAllPSBuilders();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className='container'>
        <div className='content'>
            {isLoading ? <Loading /> : null}
            <div className='header'>
            <h1>PS Builder List</h1>
            
            {  (<button className='addBtn' onClick={() => navigate(enedpoints.routepsbuildersadd)}>
                New PS Builder
            </button>)}
            </div>
            
            <div className='tableContainer'>
            <table className='table'>
                <thead>
                {psbuilder.length === 0 ? <p>There is no job created for this user.</p> :(
                    <tr>
                    <th width="85%">Builder Name</th>
                    <th width="15%">Action</th>
                </tr>
                )}
                
                </thead>
                <tbody>
                {psbuilder && psbuilder.map((builder, index) => (
                    <tr key={index} className='row'>
                    <td>{builder.name}</td>
                    <td>
                        <span className='actions'>
                        <span className='icon view' >
                            <i className="fa fa-eye"></i>
                        </span>
                        <span className='icon edit' onClick={() => navigate(`${enedpoints.routepsbuildersedit}/${builder._id}`)}>
                            <i className="fa fa-edit"></i>
                        </span>
                        {/* {
                            (decodeToken() && decodeToken().role) === 'superAdmin' && (
                                <>
                                    <span className='icon delete' onClick={() => handleShowConfirmationModal(builder._id)}>
                                        <i className="fa fa-trash"></i>
                                    </span>
                                </>
                            )
                        }   */}
                        </span>

                    </td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        </div>

        {showConfirmationModal && (
                <div className='modalOverlay'>
                    <ConfirmationAlert message="Are you sure to delete this builder?" closeModel={handleCloseConfirmationModal} onConfirm={() => handleDeletePSBuilder(pid)} />
                </div>
            )}
        
        </div>
    );
};

export default BuilderList;
