import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './PSBuilder.module.css';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService/ApiService';
import Alert from '../../utils/Alert';
import enedpoints from '../../common/endpoints';
import Loading from '../loading/Loading';

const PSBuilder = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();
    const [builderName, setBuilderName] = useState('');
    const [rows, setRows] = useState([
        { number: "1", keyword: "Outline", prompt: "generate a detailed outline for a {maxSize} blog post article about '{topic}' that will be read by people who like to {audience}. At about 80% of the article, introduce my product called '{product}' that has the following benefits : {feature} ", size: "" },
        { number: "2", keyword: "Intro", prompt: "From the article outline, write an introduction...", size: "150" },
        { number: "3", keyword: "Info", prompt: "From the article outline, write a section ...", size: "300" },
        { number: "4", keyword: "Promo", prompt: "From the article outline, write a promotion section ...", size: "400" },
        { number: "6", keyword: "Conclusion", prompt: "Write a conclusion for this article", size: "200" }
    ]);

    useEffect(() => {
        if (id) {
            const fetchData = async () => {
                try {
                    const response = await ApiService.getDataById(enedpoints.psbuilders, id);
                    setBuilderName(response.name);
                    setRows(response.table);
                    setIsLoading(false);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchData();
        }
    }, [id]);
    const handleSave = async () => {
        try {
            const data = {
                name: builderName,
                table: rows
            };
            if (id) {
                const response = await ApiService.updateData(enedpoints.psbuilders, id, data);
                Alert('Success',response.message, 'success');
                navigate('/psbuilders');
            } else {
                const response = await ApiService.storeData(enedpoints.psbuilders, data);
                Alert('Success',response.message, 'success');
                navigate('/psbuilders');
            }
        } catch (error) {
            // Handle errors if the request fails
            console.error('Error saving data:', error);
            Alert('Failed',error.response.data.error, 'error');
        }
    };
    
    const handleInputChange = (e) => {
        setBuilderName(e.target.value);
    };

    const handleRowChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };

    return (
        <div className='container'>
            <div className='content'>
                {id && isLoading && <Loading />}
                <div className='header'>
                    <h1>Prompt Sequence Builder</h1>
                </div>
            
                <form>
                    <input type="text" name="psbname" value={builderName} onChange={handleInputChange} placeholder='Builder Name' />
                </form>
                <div className='tableContainer'>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>KEYWORDS</th>
                                <th>PROMPT</th>
                                <th>SIZE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => (
                                <TableRow
                                    key={index}
                                    index={index}
                                    number={row.number}
                                    keyword={row.keyword}
                                    prompt={row.prompt}
                                    size={row.size}
                                    handleRowChange={handleRowChange}
                                />
                            ))}
                        </tbody>
                    </table>
                    
                    <button className={!id ? styles.saveBtn: styles.editBtn} onClick={handleSave}>{!id ? 'Save' : 'Update'}</button>
                </div>
            </div>
            
        </div>
    );
};

const TableRow = ({ index, number, keyword, prompt, size, handleRowChange }) => {
    const handleChange = (e, field) => {
        handleRowChange(index, field, e.target.value);
    };

    return (
        <tr>
            <td style={{ width: '5%' }}>{number}</td>
            <td style={{ width: '15%', textAlign: 'center' }}>{keyword}</td>
            <td style={{ width: '60%' }}><textarea value={prompt} onChange={(e) => handleChange(e, 'prompt')}></textarea></td>
            <td style={{ width: '20%' }}><input type="number" value={size} onChange={(e) => handleChange(e, 'size')} /></td>
        </tr>
    );
};

export default PSBuilder;
