// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-button {
    background-color: #007bff; /* Blue color for button */
    color: #fff; /* White color for text */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .confirmation-content {
    background-color: #fff; /* White background for content */
    padding: 20px;
    border-radius: 5px;
  }
  
  .confirmation-content p {
    margin-bottom: 20px;
  }
  
  .confirmation-content button {
    margin-right: 10px;
    background-color: #007bff; /* Blue color for buttons */
    color: #fff; /* White color for text */
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .line {
    display: flex;
  }


  .confirmation-content button:hover {
    background-color: #0056b3; /* Darker shade of blue on hover */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/confirmation/ConfirmationAlert.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,0BAA0B;IACrD,WAAW,EAAE,yBAAyB;IACtC,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,gCAAgC;IACtE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,sBAAsB,EAAE,iCAAiC;IACzD,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB,EAAE,2BAA2B;IACtD,WAAW,EAAE,yBAAyB;IACtC,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,aAAa;EACf;;;EAGA;IACE,yBAAyB,EAAE,kCAAkC;EAC/D","sourcesContent":[".confirmation-button {\n    background-color: #007bff; /* Blue color for button */\n    color: #fff; /* White color for text */\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .confirmation-modal {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .confirmation-content {\n    background-color: #fff; /* White background for content */\n    padding: 20px;\n    border-radius: 5px;\n  }\n  \n  .confirmation-content p {\n    margin-bottom: 20px;\n  }\n  \n  .confirmation-content button {\n    margin-right: 10px;\n    background-color: #007bff; /* Blue color for buttons */\n    color: #fff; /* White color for text */\n    border: none;\n    padding: 8px 16px;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .line {\n    display: flex;\n  }\n\n\n  .confirmation-content button:hover {\n    background-color: #0056b3; /* Darker shade of blue on hover */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
