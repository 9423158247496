import React from 'react';
import './ConfirmationAlert.css'; // Import your CSS file for styling

const ConfirmationAlert = ({ message, closeModel, onConfirm }) => {

  const handleConfirm = () => {
    onConfirm();
    closeModel();
  };

  return (
    <>
        <div className="confirmation-modal">
          <div className="confirmation-content">
            <p>{message}</p>
            <div className='line'>
              <button onClick={() => closeModel()}>Cancel</button>
              <button onClick={handleConfirm}>Confirm</button>
            </div>
          </div>
        </div>
    
    </>
  );
};

export default ConfirmationAlert;
