const downloadArticle = (article, status) => {
    const content = status === 'article' ? article.article.map(section => `${section.title}\n${section.content}\n\n`).join(''): article.scripts.map(section => `${section.title}\n${section.content}\n\n`).join('');

    const element = document.createElement('a');
    const file = new Blob([content], { type: 'text/plain' });

    element.href = URL.createObjectURL(file);
    element.download = `${article.topic}.txt`;
    document.body.appendChild(element); 
    element.click(); 
};

export default downloadArticle;