import React, { useEffect, useState } from 'react';
import styles from './Articles.module.css';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/apiService/ApiService';
import decodeToken from '../../utils/decodeToken';
import downloadArticle from '../../utils/downloadArticle';
import enedpoints from '../../common/endpoints';
import Alert from '../../utils/Alert';
import ConfirmationAlert from '../confirmation/ConfirmationAlert';
import downloadZip from '../../utils/downloadZip';
import Loading from '../loading/Loading';

const Articles = () => {
    const [isLoading, setIsLoading] = useState(true);

    const [articles, setArticles] = useState([]);
    const [selectedArticles, setSelectedArticles] = useState([]);
    const navigate = useNavigate();
    const [aid, setAid] = useState('');
    const [aName, setAName] = useState('');

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const handleShowConfirmationModal = (id, name) => {
        setAid(id);
        setAName(name);
        setShowConfirmationModal(true);
    }
    const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

    useEffect(() => {
        getArticles();
    }, []);

    const getArticles = async() => {
        try {
            const response = (decodeToken() && decodeToken().role) === 'superAdmin' ? await ApiService.getData(enedpoints.articles): await ApiService.getDataById(enedpoints.articlesbyuser, decodeToken().userId);;
            setArticles(response);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const handleDeleteArticle = async(id) => {
        try {
            const response = await ApiService.deleteData(enedpoints.articles, id);
            Alert('Warning',response.message, 'warning');
            getArticles();
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const checkboxes = document.getElementsByName('article_all');
        if(selectedArticles.length === articles.length) {
            checkboxes && checkboxes[0] && (checkboxes[0].checked = true);
        } else {
            checkboxes && checkboxes[0] && (checkboxes[0].checked = false);
        }
    }, [selectedArticles]);

    const handleCheckbox = (index) => {
        if(index === -1) {
            const checkboxes = document.getElementsByName('article_all');
            if(checkboxes[0].checked) {
                setSelectedArticles(articles);
                for(let i = 0; i < articles.length; i++) {
                    document.getElementsByName(`article_${i}`)[0].checked = true;
                }
            } else {
                setSelectedArticles([]);
                for(let i = 0; i < articles.length; i++) {
                    document.getElementsByName(`article_${i}`)[0].checked = false;
                }
            }
        } else {
            const checkboxes = document.getElementsByName(`article_${index}`);
            if(checkboxes[0].checked) {
                setSelectedArticles([...selectedArticles, articles[index]]);
            } else {
                setSelectedArticles(selectedArticles.filter(article => article._id !== articles[index]._id));
            }
        }
    }

    return (
        <div className='container'>
            <div className='content'>
                {isLoading ? <Loading /> : null}
                <div className='header'>
                    <h1>Articles</h1>
                    <span className={styles.error}></span>
                    <button disabled={selectedArticles.length === 0} className='icon info' title='Download All' onClick={() => downloadZip(articles)}>
                        <i className="fa fa-file-archive-o"></i>
                    </button>
                </div>
                
                <div className='tableContainer'>
                    <table className='table'>
                    <thead>
                        {articles.length === 0 ? <p>There is no article generated for this user.</p> : (
                            <tr>
                            <th width="10%"><input name={'article_all'} type="checkbox" onClick={() => handleCheckbox(-1)} /> All</th>
                            <th width="20%">Title</th>
                            <th width="30%">Content</th>
                            <th width="30%">Save Date</th>
                            <th width="15%">Action</th>
                            </tr>
                        )}
                        
                    </thead>
                    <tbody>
                        {articles.map((article, index) => (
                        <tr key={index}>
                            <td><input name={`article_${index}`} type="checkbox" onClick={() => handleCheckbox(index)} /></td>
                            <td>{article.topic}</td>
                            <td>{article.article.length}</td>
                            <td>{new Date(article.createdAt).toLocaleString()}</td>
                            <td>
                            <span className='actions'>
                                <span className='icon view' title='View Article' onClick={() => navigate(`/articles/${article._id}`)}>
                                    <i className="fa fa-eye"></i>
                                </span>
                                <span className='icon download' title='Download Article' onClick={() => downloadArticle(article)}>
                                    <i className="fa fa-download"></i>
                                </span>
                                {
                                    (decodeToken() && decodeToken().role) === 'superAdmin' && (
                                        <>
                                            <span className='icon delete' title='Delete Article' onClick={() => handleShowConfirmationModal(article._id, article.topic)}>
                                                <i className="fa fa-trash"></i>
                                            </span>
                                        </>
                                    )
                                }  
                            </span>
                            </td>
                        </tr>
                        ))}
                    
                    </tbody>
                    </table>
                </div>
        
            </div>
            {showConfirmationModal && (
                <div className='modalOverlay'>
                    <ConfirmationAlert message={`Are you sure to delete "${aName}"?`} closeModel={handleCloseConfirmationModal} onConfirm={() => handleDeleteArticle(aid)} />
                </div>
            )}
        </div>
    );
};

export default Articles;
