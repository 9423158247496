// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebar__go0QL {
    width: 220px;
    height: 100vh;
    background-color: #2980b9; /* Darker Shade of Blue */
    color: #fff; /* White */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Sidebar_navFooter__RlWSS {
    padding-bottom: 20px;
}

.Sidebar_logo__NP9ho {
    max-width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}


.Sidebar_navLinks__dtoFh {
    margin-top: 30px;
    list-style-type: none;
    padding: 0;
}

ul {
    list-style-type: none;
}

.Sidebar_navLinks__dtoFh li {
    margin-bottom: 10px;
    background-color: #2980b9;
    padding: 10px;
    transition:all 0.5s ease;
}

.Sidebar_navLinks__dtoFh li:last-child {
    margin-bottom: 0;
}

.Sidebar_navLinks__dtoFh li span {
    margin-right: 15px;
}

.Sidebar_navLinks__dtoFh a {
    text-decoration: none;
    color: #fff;
    transition: color 0.3s ease;
}

.Sidebar_navLinks__dtoFh li:hover {
    background-color: #8688d6; /* Hot white */
}

.Sidebar_active__K5Ns8 {
    background-color: #898bf5;
}

`, "",{"version":3,"sources":["webpack://./src/components/sidebar/Sidebar.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB,EAAE,yBAAyB;IACpD,WAAW,EAAE,UAAU;IACvB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB;;;AAGA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,2BAA2B;AAC/B;;AAEA;IACI,yBAAyB,EAAE,cAAc;AAC7C;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".sidebar {\n    width: 220px;\n    height: 100vh;\n    background-color: #2980b9; /* Darker Shade of Blue */\n    color: #fff; /* White */\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.navFooter {\n    padding-bottom: 20px;\n}\n\n.logo {\n    max-width: 100%;\n    height: auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding-top: 10px;\n}\n\n\n.navLinks {\n    margin-top: 30px;\n    list-style-type: none;\n    padding: 0;\n}\n\nul {\n    list-style-type: none;\n}\n\n.navLinks li {\n    margin-bottom: 10px;\n    background-color: #2980b9;\n    padding: 10px;\n    transition:all 0.5s ease;\n}\n\n.navLinks li:last-child {\n    margin-bottom: 0;\n}\n\n.navLinks li span {\n    margin-right: 15px;\n}\n\n.navLinks a {\n    text-decoration: none;\n    color: #fff;\n    transition: color 0.3s ease;\n}\n\n.navLinks li:hover {\n    background-color: #8688d6; /* Hot white */\n}\n\n.active {\n    background-color: #898bf5;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar_sidebar__go0QL`,
	"navFooter": `Sidebar_navFooter__RlWSS`,
	"logo": `Sidebar_logo__NP9ho`,
	"navLinks": `Sidebar_navLinks__dtoFh`,
	"active": `Sidebar_active__K5Ns8`
};
export default ___CSS_LOADER_EXPORT___;
