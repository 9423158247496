import React, { useEffect, useState } from 'react';
import styles from './JobModal.module.css';
import ApiService from '../../services/apiService/ApiService';
import decodeToken from '../../utils/decodeToken';
import Alert from '../../utils/Alert';
import enedpoints from '../../common/endpoints';
import Loading from '../loading/Loading';
import Modal from '../modals/Modal';


const JobModal = ({ handleClose, id, service }) => {  
    const [isLoading, setIsLoading] = useState(id ? true : false); 
    const [loadMessage, setLoadMessage] = useState('Loading...');
    const [jobname, setJobname] = useState('');
    const [topic, setTopic] = useState('');
    const [maxSize, setMaxSize] = useState('');
    const [product, setProduct] = useState('');
    const [audience, setAudience] = useState('');
    const [feature, setFeature] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [builder, setBuilder] = useState([]);
    const [selectedBuilder, setSelectedBuilder] = useState({});
    const [error, setError] = useState(null);
    const [jobId, setJobId] = useState(id);

    const [showHide, setShowHide] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        getAllPSBuilders();
    }, []);

    useEffect(() => {
        if (id && builder) {
            getJobById(id, builder);
        }
    }, [id, builder]);

    const getJobById = async (id, builder) => {
        try {
            const response = await ApiService.getDataById(enedpoints.jobs, id);
            setJobname(response.jobname);
            setTopic(response.topic.join('\n'));
            setMaxSize(response.maxSize);
            setProduct(response.product);
            setAudience(response.audience);
            setFeature(response.feature);
    
            
            if (builder && builder.length > 0) {
                const selected = builder.find(b => b.id === response.psbuilder_id);
                if (selected) {
                    setSelectedBuilder(selected);
                    setSelectedOption(selected.name);
                } else {
                    console.error("Builder not found for ID:", response.psbuilder_id);
                }
            }

            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSave = async () => {
        try {
            let data = {
                jobname,
                topic: topic.split('\n'),
                maxSize: parseInt(maxSize),
                product,
                audience,
                feature,
                psbuilder: builder.find(b => b.name === selectedOption)._id,
                userId: decodeToken().userId
            };
            if(id) {
                data._id = id;
                const response = await ApiService.updateData(enedpoints.jobs, id, data);
                return response;
            } else {
                const response = await ApiService.storeData(enedpoints.jobs, data);
                return response;
            }    
        } catch (error) {
            console.error('Error during signup:', error);
            Alert('Failed',error.response.data.error, 'error');
        }
    }

    const handleSubmit = async () => {
        try {
            const response = await handleSave();
            handleClose();
            Alert('Success',response.message, 'success');
        } catch (error) {
            console.error('Error during signup:', error);
            Alert('Failed',error.response.data.error, 'error');
        }
    }

    const generateArticles = async(jobId, topicLis=[]) => {
        try { 
            // console.log(jobId, topicLis);
            setLoadMessage('Articles is generating. Please wait...');
            setIsLoading(true);    
            const response = await ApiService.generateArticles(enedpoints.articlegenerate, jobId, topicLis);
            if(response) {
                Alert('Success',response.message, 'success');
                setIsLoading(false);
                handleClose();
            }
        } catch (error) {
          console.error(error);
        }
    };

    const handleSubmitAndGenerate = async () => {
        try {
            const response = await handleSave();
            Alert('Success',response.message, 'success');
            if(response) {
                setJobId(response.data._id);
                handleShowModal();
            }
        } catch (error) {
            console.error('Error during signup:', error);
            Alert('Failed',error.response.data.error, 'error');
        }
    }

    const getAllPSBuilders = async () => {
        try {
            const response = await ApiService.getData(enedpoints.psbuilders);
            setBuilder(response);
        } catch (error) {
            console.error(error);
        }
    };

    const handleInput = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'jobname':
                setJobname(value);
                break;
            case 'topic':
                setTopic(value);
                break;
            case 'maxSize':
                if(value <= service.word_limit) {
                    setError(null);
                    setMaxSize(value);
                } else {
                    setError(`Maximum number of word is ${service.word_limit}`);
                }
                break;
            case 'product':
                setProduct(value);
                break;
            case 'audience':
                setAudience(value);
                break;
            case 'feature':
                setFeature(value);
                break;
            default:
                break;
        }
    };

    const handleBuilderChange = (e) => {
        const selected = builder.find(item => item.name === (e.target.value));
        setSelectedBuilder(selected);
        setSelectedOption(e.target.value);
    }
  
    return (
        <div className={styles.modal}>
        <div className={styles.modalContent}>
            {isLoading ? <Loading message={loadMessage} /> : null}
            <div className={styles.modalHeader}>
                <h3>{id ? 'Update This Job' : 'Create New Job'}</h3>
                <span className={styles.close} onClick={handleClose}>&times;</span>
            </div>

            <div className={styles.modalBody}>
                <form>
                    {error && <p className={styles.error}>{error}</p>}
                    <input type="text" name='jobname' value={jobname} onChange={handleInput} placeholder="Job name" />

                    <select value={selectedOption} className={styles.select} onChange={(e) => handleBuilderChange(e)}>
                        <option value="">Select Builder</option>
                         {builder.map((b) => (
                             <option key={b._id} value={b.name}>{b.name}</option>
                         ))}
                    </select>
                    
                    { selectedOption !== '' && (
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px', marginBottom: '10px'}}>
                            <label className='label'>Builder Description</label>
                            <span className={styles.infoBtn} onClick={() => setShowHide(!showHide)}>{showHide ? 'Hide' : 'Show' }</span>
                        </div>
                    )}
                    

                    {
                        showHide && selectedBuilder && (
                            <div className='tableContainer'>
                                <table className="table" style={{ border: '1px solid #ccc'}}>
                                    <thead>
                                        <tr>
                                            <th>Keyword</th>
                                            <th>Prompt</th>
                                            <th>Max size</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedBuilder.table.map((row) => (
                                            <tr key={row.number}>
                                                <td>{row.keyword}</td>
                                                <td>{row.prompt}</td>
                                                <td>{row.size}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )
                    }

                    <textarea name='topic' rows={2} value={topic} onChange={handleInput} placeholder='Topic'></textarea>
                    <input type="text" name='maxSize' value={maxSize} onChange={handleInput} placeholder="Max size" />
                    <input type="text" name='product' value={product} onChange={handleInput} placeholder="Product" />
                    <input type="text" name='audience' value={audience} onChange={handleInput} placeholder="Audience" />
                    <textarea name='feature' rows={3} value={feature} onChange={handleInput} placeholder='Feature'></textarea>

                </form>

                </div>

                <div className={styles.modalFooter}>
                    <button className={`${styles.modalBtn} ${id ? styles.updateBtn : styles.saveBtn}`} onClick={handleSubmit}>{id ? 'Update' : 'Save'} Only</button>
                    <button className={`${styles.modalBtn} ${id ? styles.updateBtn : styles.saveBtn}`} onClick={handleSubmitAndGenerate}>{id ? 'Update' : 'Save'} & Generate Article</button>
                </div>

            </div>

            {showModal && <Modal handleClose={handleCloseModal} jobId={jobId} generateArticles={generateArticles}/>}
        </div>
    );
};

export default JobModal;