import React, { useEffect, useState } from 'react';
import styles from './DisplayCard.module.css';
import ApiService from '../../services/apiService/ApiService';
import enedpoints from '../../common/endpoints';
import Card from './Card';

const DisplayCard = ({ handleClose }) => {
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchPackageById = async () => {
            try {
                const response = await ApiService.getData(enedpoints.packages);
                setPackages(response.data);
            } catch (error) {
                console.error('Error fetching package:', error);
            }
        };

        fetchPackageById();
    }, []);

    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <span className={styles.close} onClick={handleClose}>&times;</span>
                <div className={styles.display}>
                    {packages && packages.map((pack, index) => (
                        <Card key={index} handleClose={handleClose} pack={pack} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DisplayCard;

