import React, { useEffect, useState } from 'react';
import styles from './Package.module.css';
import ApiService from '../../services/apiService/ApiService';
import ModalForm from './ModalForm';
import DisplayCard from './DisplayCard';
import decodeToken from '../../utils/decodeToken';
import enedpoints from '../../common/endpoints';
import Loading from '../loading/Loading';

const Package = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [pid, setPid] = useState('');
  const [toggleInfo, setToggleInfo] = useState(new Array(packages.length).fill(false));

  const handleToggleInfo = (index) => {
    const newToggleInfo = [...toggleInfo];
    newToggleInfo[index] = !newToggleInfo[index];
    setToggleInfo(newToggleInfo);
  };

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = (id) => {
    setPid(id);
    setShowModal(true);
  }
  const handleCloseModal = () => setShowModal(false);

  const [showCard, setShowCard] = useState(false);
  const handleShowCard = (id) => {
    setPid(id);
    setShowCard(true);
  }
  const handleCloseCard = () => setShowCard(false);

  useEffect(() => {
    getPackages();
  }, []);

  useEffect(() => {
    getPackages();
  }, [showModal]);

  const getPackages = async () => {
    try {
      const response = await ApiService.getData(enedpoints.packages);
      setPackages(response);
      setIsLoading(false);
    } catch (error) {
      console.error(error.response.data.error);
    }
  };

  return (
    <div className='container'>
      <div className='content'>
        { isLoading && <Loading /> }
        <div className='header'>
          <h1>Packages</h1>

          <span className={styles.error}></span>
          {
            (decodeToken() && decodeToken().role === 'superAdmin') ? (<button className='addBtn' onClick={() => handleShowModal(null)}>New Package</button>) : (null)
          }
        </div>
        
        {/* <div className='tableContainer'>
          { packages ? packages.map((pkg, index) => (
            <table className='table'>
              <thead>
                <tr>
                  <th colSpan={3} width="75%">{pkg.package_name}</th>
                  <th>
                    <span className='actions'>
                      <span className='icon view' title='View' onClick={() => handleShowCard(pkg._id)}>
                          <i className="fa fa-eye"></i>
                      </span>
                      <span className='icon info' title='Info' onClick={() => handleToggleInfo(index)}>
                          <i className="fa fa-info-circle"></i>
                      </span>
                      {
                          (decodeToken() && decodeToken().role) === 'superAdmin' && (
                              <>
                                  <span className='icon edit' title='Edit' onClick={() => handleShowModal(pkg._id)}>
                                      <i className="fa fa-edit"></i>
                                  </span>
                                  <span className='icon delete' title='Delete'>
                                      <i className="fa fa-trash"></i>
                                  </span>
                              </>
                          )
                      }  
                    </span>
                  </th>
                </tr>
                {
                  toggleInfo[index] && (
                    <tr>
                      <th>  Service name </th>
                      <th>  Job limit </th>
                      <th>  Articles limit </th>
                      <th>  Word limit </th>
                    </tr>
                  )
                }
                
              </thead>
              <tbody>
              {toggleInfo[index] && pkg.services && pkg.services.map((service, index) => (
                  <tr key={index} className='row'>
                    <td>{service.service_name ? service.service_name : ''}</td>
                    <td>{service.job_limit ? service.job_limit > 99 ? 'Unlimited' : service.job_limit : ''}</td>
                    <td>{service.articles_limit ? service.articles_limit > 99 ? 'Unlimited' : service.articles_limit : ''}</td>
                    <td>{service.word_limit ? service.word_limit >= 5000 ? 'Unlimited' : service.word_limit : ''}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )) : "" }
          
        </div> */}
      </div>
      
    
      {showModal && <ModalForm handleClose={handleCloseModal} pid={pid} ></ModalForm>}
      {showCard && <DisplayCard handleClose={handleCloseCard}></DisplayCard>}

    </div>
  );
};

export default Package;
